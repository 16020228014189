import React from 'react'
import styled from 'styled-components'
import Hero from '../../../../resuable/hero/hero'
import HeroImage from '../../../../../images/offers/womens-day/hero.jpg'
import { colors } from '../../../../../utils/styles/styles'

const StyledHeaderSection = styled.div`
  .womensdayhero {
    height: 70vh;
    margin-bottom: 2rem;
    background: url(${HeroImage});
    background-color: ${colors.priDark};
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
    /* background-attachment: fixed; */
  }
`

const HeaderSection = (props) => {
  return (
    <StyledHeaderSection>
      <Hero 
        title="Acknowledgment is a choice, but challenging is your right!"
        className="womensdayhero"
      />
    </StyledHeaderSection>
  )
}

HeaderSection.defaultProps = {

}

export default HeaderSection