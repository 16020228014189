import React from 'react'
import HeaderSection from '../../components/page/offers-components/womens-day/header-section/header-section'
import Layout from '../../components/resuable/layout/layout'
import BodySection from '../../components/page/offers-components/womens-day/body-section/body-section'
import Joinus from '../../components/resuable/joinus/joinus'
import SEO from '../../components/resuable/seo/seo'

const WomensDay = () => {
  return (
    <Layout>
      <SEO 
        title="LeadMirror - Building growth opportunities for budding entreprenuers"
        description="LeadMirror uncovers future opportunities for marketing with simplified growth hacking techniques"
      />
      <HeaderSection />
      <BodySection />
      <Joinus />
    </Layout>
  )
}

export default WomensDay
