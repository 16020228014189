import React from "react"
import styled from "styled-components"
import Section from "../../../../resuable/section/section"
import Row from "../../../../resuable/row/row"
import WomenOne from "../../../../../images/offers/womens-day/women-1.jpg"
import WomenTwo from "../../../../../images/offers/womens-day/women-2.jpg"
import WomenThree from "../../../../../images/offers/womens-day/women-3.jpg"
import { ButtonType } from "../../../../resuable/button"
import { colors } from "../../../../../utils/styles/styles"

const StyledBodySection = styled.div`
  width: 100%;
  div.entry__row {
    padding: 16rem 1rem 18rem;
    h2 {
      font-size: 24px;
      color: #dfdfdf;
      text-align: center;
    }
  }
  div.content__row {
    width: 100;
    display: flex;
    div.content__row-image,
    div.content__row-text {
      flex: 50%;
    }
    div.content__row-image {
      img {
        height: 550px;
        width: 100%;
        object-fit: cover;
      }
    }
    div.content__row-text {
      padding: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      h3,
      p {
        width: 100%;
        max-width: 400px;
        text-align: left;
      }
      h3 {
        font-size: 2rem;
      }
      p {
        font-size: 1.6rem;
      }
      a button {
        margin-top: 20px;
      }
      p.action a {
        color: ${colors.secDark};
        font-weight: 700;
        font-style: italic;
        text-decoration: underline;
      }
    }
    &:not(:last-child) {
      margin-bottom: 12rem;
    }
  }
`

const BodySection = props => {
  return (
    <Section fullwidth={true} padding="0px">
      <StyledBodySection>

        {/* Row One */}
        <Row fullwidth={false} className="entry__row">
          <h2>
            Product for some, a possibility for all! LeadMirror believes in
            creating innovative opportunities to make impossible, possible!
          </h2>
        </Row>
        <Row className="content__row">
          <div className="content__row-image">
            <img src={WomenOne} alt="women talking" />
          </div>
          <div className="content__row-text">
            <h3>Now beating the competition made simple</h3>
            <p>
              Relying on organic traffic is playing like a lottery. The odds
              aren’t in your favor. No matter how much traffic you decide to buy
              through either paid advertising or other social media channels,
              one day you will need Google. Understanding the keyword strategy
              helps you win Google search results game.
            </p>
            <p className="action">
              <a
                href="https://app.leadmirror.com/#/signup/wizard/1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Convert 60% more of your leads into customers while spending 20%
                less. →
              </a>
            </p>
          </div>
        </Row>

        {/* Row Two */}
        <Row className="content__row">
          <div className="content__row-text">
            <h3>The 2020 trend that we've been observing</h3>
            <p>
              Google wants to deliver the search results a group of humans would
              deliver. Just think about it for a second- If Google was a human,
              would it return your website as the top result for the search
              terms you’re chasing? Or you feel gratitude that Google isn’t a
              human and allows you to “trick” it?
              <br />
            </p>
            <p>
              If your website doesn’t deliver what people are searching for, No
              excellent building strategy can solve your problem.
            </p>
            <p className="action">
              <a
                href="https://app.leadmirror.com/#/signup/wizard/1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Make Google love your website and users happy
                when they visit your webpage →
              </a>
            </p>
          </div>
          <div className="content__row-image">
            <img src={WomenTwo} alt="women talking" />
          </div>
        </Row>

        {/* Row Three */}
        <Row className="content__row">
          <div className="content__row-image">
            <img src={WomenThree} alt="women talking" />
          </div>
          <div className="content__row-text">
            <h3>Trust-worthiness makes you unique</h3>
            <p>
              Assuming your website is in good shape and delivers the value
              people want, If Google was a human, what kind of links would it
              consider to be reputable and worthy of considering when ranking a
              website? Targeting the highest quality and helpful links from
              relevant articles from exclusive websites helps to win the Link
              Building Game.
            </p>
            <p>
              Targeting the highest quality and helpful links from relevant
              articles from exclusive websites helps to win the Link Building
              Game.
            </p>
            <p className="action">
              <a
                href="https://app.leadmirror.com/#/signup/wizard/1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Overcome your competitors with a stronger link profile now →
              </a>
            </p>
          </div>
        </Row>
      </StyledBodySection>
    </Section>
  )
}

BodySection.defaultProps = {}

export default BodySection